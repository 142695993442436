import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
d = new Date();
days = ["Sunday (रविवार)","Monday (सोमवार)","Tuesday (मंगलवार)","Wednesday (बुधवार)","Thursday (गुरुवार)","Friday (शुक्रवार)","Saturday (शनिवार)"];
today = this.days[this.d.getDay()];

  constructor() { }

  ngOnInit() {
  }

}
