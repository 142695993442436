import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Aarti } from '../aarti.model';
import { AartiService } from '../aarti.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-aarti-detail',
  templateUrl: './aarti-detail.component.html',
  styleUrls: ['./aarti-detail.component.css']
})
export class AartiDetailComponent implements OnInit {
  aarti: Aarti;
  id: string;
  
  dangerousVideoUrl: string;
  videoUrl: SafeResourceUrl;

  constructor(private aartiService: AartiService,
              private route: ActivatedRoute,
              private router: Router,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.route.params
      .subscribe(
        (params: Params) => {
          this.id = params['id'];
          this.aarti = this.aartiService.getAarti(this.id);
          
          //video urn change every time with url change
          this.dangerousVideoUrl = this.aarti.video;
          this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousVideoUrl);  
        }
      );

        
  }

}
