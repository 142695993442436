<div class="boq" [style.backgroundImage]="'url('+ aarti.cover +')'">
  <div class="by">
    <div class="bor">
      <img class="us bos" [src]="aarti.icon">
      <h3 class="bou">{{ aarti.title }}</h3>
      <p class="bot">
        {{ aarti.hindiName }}<BR> {{ aarti.engName }}
      </p>
    </div>
  </div>

</div>

<!-- <div [innerHtml]="aarti.lyrics"></div> /assets/img/ganesh.jpg -->

<div style="text-align: center;padding-top:180px;background: url('/assets/img/bg_subtle-pattern-2.jpg');">
<iframe width="320" height="180" [src]="videoUrl" frameborder="0" allowfullscreen></iframe>
</div>

<div innerHtml="{{aarti.lyrics}}" class="noselect"
style="text-align: center;padding-top:10px;padding-bottom:30px;
font-size: 18px;line-height: 2;
color:red;text-shadow: 0 1px 1px #000;
background: url('/assets/img/bg_subtle-pattern-2.jpg');">
</div>

<!-- FB Comment Start -->
<!-- <div id="fb-root"></div>
<div class="fb-comments" data-href="https://developers.facebook.com/docs/plugins/comments#configurator" data-numposts="5"></div> -->
<!-- FB Comment Start -->

<hr class="featurette-divider">