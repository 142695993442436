import { Component, OnInit, Input } from '@angular/core';

import { Chalisa } from '../../chalisa.model';

@Component({
  selector: 'app-chalisa-item',
  templateUrl: './chalisa-item.component.html',
  styleUrls: ['./chalisa-item.component.css']
})
export class ChalisaItemComponent implements OnInit {
  @Input() chalisa: Chalisa;
  @Input() index: string;

  ngOnInit() {
  }
}
