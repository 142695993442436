import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  tog() {
    var element = document.getElementById("menu");
    element.classList.toggle("active");
  }
  
}
