<a style="cursor: pointer;" [routerLink]="[index]">
<div class="col-lg-12 col-xl-12 col-md-12">
  <div class="card border-0 shadow cardw">
    <img [src]="aarti.icon" class="card-img-top cardw" alt="{{ aarti.title }}">
    <div class="card-body text-center text-danger">
      <h5 class="card-title mb-0 cardf1">{{ aarti.title }}</h5>
      <div class="card-text text-black-50 cardf2">{{ aarti.hindiName }}<br>{{ aarti.engName }}</div>
    </div>
  </div>
</div>
</a>