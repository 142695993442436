<div class="container">
    <div class="jumbotron jumbotron-fluid">
        <div class="container">
          <h1 class="display-4 text-danger">Chalisa Collection</h1>
          <p class="lead">The word "chālīsā" is derived from "chālīs", which means the number forty in Hindi. भगवान की सरल भाषा में की जाने वाली प्रार्थना को चालीसा कहा जाता है। इष्ट देव की चालीसा का पाठ करने से व्यक्ति के जीवन में अद्भुत प्रभाव पड़ता है। इसके साथ ही इस प्रार्थना को चालीसा इसलिए कहा जाता है क्योंकि इसमें चालीस लाइनें होती हैं। सरलता से ईश्वर को प्रसन्न करने का ये तरीका हिंदू धर्म में बहुत लोकप्रिय माना जाता है।</p>
        </div>
    </div>
    <div class="row" style="text-align:center">
    <app-chalisa-item style="margin-top:25px;" 
      *ngFor="let chalisaEl of chalisas"
      [chalisa]="chalisaEl"
      [index]="chalisaEl.url"></app-chalisa-item>
    </div>
</div>