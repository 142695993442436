<div id="myCarousel" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
    <li data-target="#myCarousel" data-slide-to="1"></li>
    <li data-target="#myCarousel" data-slide-to="2"></li>
  </ol>
  <div class="carousel-inner">
    
    <div class="carousel-item active">
      <img src="/assets/img/cover-3.jpg" class="bd-placeholder-img d-block" alt="">      
      <div class="container">
        <div class="carousel-caption text-left" style="text-shadow: 0 2px 2px #000;">
          <h1>Bhakti (भक्ति) = Attachment, Participation, Fondness for, Homage, Faith, Love, Devotion, Worship, Purity</h1>
          <!-- <p>BHAKTI = Attachment, Participation, Fondness for, Homage, Faith, Love, Devotion, Worship, Purity</p> -->
          <p><a class="btn btn-sm btn-danger" routerLink="/about" role="button">Welcome to Bhakti App</a></p>
        </div>
      </div>
    </div>

    <div class="carousel-item">
        <img src="/assets/img/cover-2.jpg" class="bd-placeholder-img d-block" alt="">      
      <div class="container">
        <div class="carousel-caption text-left" style="text-shadow: 0 2px 2px #000;">
          <h1>Aarti Collection</h1>
          <p>Aarti is derived from the Sanskrit word आरात्रिक (ArAtrika) which means something that removes rātrī, darkness. आरती हिन्दू धर्म की पूजा परंपरा का एक अत्यंत महत्वपूर्ण कार्य है.</p>
          <p><a class="btn btn-sm btn-danger" routerLink="/aarti" role="button">Browse gallery</a></p>
        </div>
      </div>
    </div>

    <div class="carousel-item">
        <img src="/assets/img/cover-1.jpg" class="bd-placeholder-img d-block" alt="">      
      <div class="container">
        <div class="carousel-caption text-left" style="text-shadow: 0 2px 2px #000;">
          <h1>Chalisa Collection</h1>
          <p>The word "chālīsā" is derived from "chālīs", which means the number forty in Hindi. भगवान की सरल भाषा में की जाने वाली प्रार्थना को चालीसा कहा जाता है। इष्ट देव की चालीसा का पाठ करने से व्यक्ति के जीवन में अद्भुत प्रभाव पड़ता है।</p>
          <p><a class="btn btn-sm btn-danger" routerLink="/chalisa/" role="button">Browse gallery</a></p>
        </div>
      </div>
    </div>
  </div>

  <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>



<!-- Marketing messaging and featurettes
================================================== -->
<!-- Wrap the rest of the page in another container to center all the content. -->

<div class="container marketing">
<!-- Three columns of text below the carousel -->
<h1 class="featurette-heading text-danger" style="padding-bottom:50px;">Important prayer for {{today}}</h1>

<div class="row">

<div class="col-lg-4" *ngIf="today == 'Sunday (रविवार)'"><a routerLink="/aarti/shri-surya-deva-aarti"><img src="/assets/img/icon/Surya.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Shri Surya Deva Aarti</h2></a></div>
<div class="col-lg-4" *ngIf="today == 'Sunday (रविवार)'"><a routerLink="/chalisa/shri-surya-deva-chalisa"><img src="/assets/img/icon/Surya.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Shri Surya Deva Chalisa</h2></a></div>


<div class="col-lg-4" *ngIf="today == 'Monday (सोमवार)'"><a routerLink="/aarti/shiva-aarti"><img src="/assets/img/icon/Mahadev.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Shiva Aarti</h2></a></div>
<div class="col-lg-4" *ngIf="today == 'Monday (सोमवार)'"><a routerLink="/chalisa/shiva-chalisa"><img src="/assets/img/icon/shiva.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Shiva Chalisa</h2></a></div>
<div class="col-lg-4" *ngIf="today == 'Monday (सोमवार)'"><a routerLink="/aarti/mahadev-aarti"><img src="/assets/img/icon/Mahadev.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Mahadev Aarti</h2></a></div>


<div class="col-lg-4" *ngIf="today == 'Tuesday (मंगलवार)'"><a routerLink="/aarti/shri-hanuman-aarti"><img src="/assets/img/icon/hanuman.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Shri Hanuman Aarti</h2></a></div>
<div class="col-lg-4" *ngIf="today == 'Tuesday (मंगलवार)'"><a routerLink="/chalisa/shri-hanuman-chalisa"><img src="/assets/img/icon/hanuman.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Shri Hanuman Chalisa</h2></a></div>
<div class="col-lg-4" *ngIf="today == 'Tuesday (मंगलवार)'"><a routerLink="/aarti/mangal-murti-jai-jai-hanumanta"><img src="/assets/img/icon/hanuman.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Mangal Murti Jai Jai Hanumanta</h2></a></div>


<div class="col-lg-4" *ngIf="today == 'Wednesday (बुधवार)'"><a routerLink="/aarti/shri-ganesh-aarti"><img src="/assets/img/icon/ganesh.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Shri Ganesh Aarti</h2></a></div>
<div class="col-lg-4" *ngIf="today == 'Wednesday (बुधवार)'"><a routerLink="/chalisa/shri-ganesh-chalisa"><img src="/assets/img/icon/ganpati.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Shri Ganesh Chalisa</h2></a></div>
<div class="col-lg-4" *ngIf="today == 'Wednesday (बुधवार)'"><a routerLink="/aarti/shri-krishna-aarti"><img src="/assets/img/icon/krishna.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Shri Krishna Aarti</h2></a></div>


<div class="col-lg-4" *ngIf="today == 'Thursday (गुरुवार)'"><a routerLink="/aarti/jagdish-aarti"><img src="/assets/img/icon/Jagdish.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Lord Jagdish Aarti</h2></a></div>
<div class="col-lg-4" *ngIf="today == 'Thursday (गुरुवार)'"><a routerLink="/aarti/satyanarayan-aarti"><img src="/assets/img/icon/lakshmi-narayan.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Lord Satyanarayan Aarti</h2></a></div>
<div class="col-lg-4" *ngIf="today == 'Thursday (गुरुवार)'"><a routerLink="/aarti/om-jai-brihaspati-deva"><img src="/assets/img/icon/brahspati.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Brihaspatiwar Ki Aarti</h2></a></div>


<div class="col-lg-4" *ngIf="today == 'Friday (शुक्रवार)'"><a routerLink="/chalisa/durga-mata-chalisa"><img src="/assets/img/icon/parbati.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Durga Mata Chalisa</h2></a></div>
<div class="col-lg-4" *ngIf="today == 'Friday (शुक्रवार)'"><a routerLink="/aarti/lakshmi-mata-aarti"><img src="/assets/img/icon/Lakshmi.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Lakshmi Mata Aarti</h2></a></div>
<div class="col-lg-4" *ngIf="today == 'Friday (शुक्रवार)'"><a routerLink="/aarti/durga-mata-aarti"><img src="/assets/img/icon/durga.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Durga Mata Aarti</h2></a></div>


<div class="col-lg-4" *ngIf="today == 'Saturday (शनिवार)'"><a routerLink="/aarti/shri-shanidev-aarti"><img src="/assets/img/icon/shani.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Shanidev Aarti</h2></a></div>
<div class="col-lg-4" *ngIf="today == 'Saturday (शनिवार)'"><a routerLink="/chalisa/shri-shani-chalisa"><img src="/assets/img/icon/shani.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Shri Shani Chalisa</h2></a></div>
<div class="col-lg-4" *ngIf="today == 'Saturday (शनिवार)'"><a routerLink="/aarti/shri-hanuman-aarti"><img src="/assets/img/icon/hanuman.jpg" class="bd-placeholder-img rounded-circle" width="140" height="140" alt="">        
<h2>Shri Hanuman Aarti</h2></a></div>


</div><!-- /.row -->


    <!-- START THE FEATURETTES -->

    <hr class="featurette-divider">

    <div class="row featurette">
      <div class="col-md-7">
        <h2 class="featurette-heading text-danger">Collection of Devotional Aarti <span class="text-muted">Aartis are sung to glorify the deity.</span></h2>
        <p class="lead">Aarti is derived from the Sanskrit word आरात्रिक (ArAtrika) which means something that removes rātrī, darkness.</p>
        <p><a class="btn btn-lg btn-danger" style="color:#fff;" routerLink="/aarti" role="button">Browse gallery</a></p>
      </div>
      <div class="col-md-5">
          <img src="/assets/img/icon/icon-2.jpg" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" alt="">        
      </div>
    </div>

    <hr class="featurette-divider">

    <div class="row featurette">
      <div class="col-md-7 order-md-2">
        <h2 class="featurette-heading text-danger">Chalisa Collection <span class="text-muted"></span></h2>
        <p class="lead">The word "chālīsā" is derived from "chālīs", which means the number forty in Hindi. भगवान की सरल भाषा में की जाने वाली प्रार्थना को चालीसा कहा जाता है। इष्ट देव की चालीसा का पाठ करने से व्यक्ति के जीवन में अद्भुत प्रभाव पड़ता है। इसके साथ ही इस प्रार्थना को चालीसा इसलिए कहा जाता है क्योंकि इसमें चालीस लाइनें होती हैं। सरलता से ईश्वर को प्रसन्न करने का ये तरीका हिंदू धर्म में बहुत लोकप्रिय माना जाता है।</p>
        <p><a class="btn btn-lg btn-danger" style="color:#fff;" routerLink="/chalisa" role="button">Browse gallery</a></p>
      </div>
      <div class="col-md-5 order-md-1">
          <img src="/assets/img/icon/icon-1.jpg" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" alt="">        
      </div>
    </div>

    <hr class="featurette-divider">

    <div class="row featurette">
      <div class="col-md-7">
        <h2 class="featurette-heading text-danger">Shri Durga Saptashati <span class="text-muted">दुर्गा सप्तशती</span></h2>
        <p class="lead">Durga Saptashati which is also known as Devi Mahatmya and Chandi Path is a Hindu religious text describing the victory of the Goddess Durga over the demon Mahishasura. दुर्गा सप्तशती में काव्य रूप में बताया गया है कि कैसे मां दुर्गा का अवतरण हुआ और किस तरह उन्होंने बार बार पृथ्वी पर दैत्यों के अत्याचार को ख़त्म किया। कहते हैं दुर्गा सप्तशती का पाठ करने से आपको, भय, शोक और सभी प्रकार के कष्टों से मुक्ति मिल जाती है। दुर्गा सप्तशती पाठ में 13 अध्याय है। इसमे मां दुर्गा के द्वारा लिए गये अवतारों की भी जानकारी दी गई है।</p>
        <p><a class="btn btn-lg btn-danger" style="color:#fff;" routerLink="/durga-saptashati" role="button">Click here</a></p>
      </div>
      <div class="col-md-5">
          <img src="/assets/img/icon/durga.jpg" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" alt="">        
      </div>
    </div>

    <hr class="featurette-divider">

    <div class="row featurette">
      <div class="col-md-7 order-md-2">
        <h2 class="featurette-heading text-danger">Sunderkand | सुन्दर काण्ड <span class="text-muted">श्री रामचरित मानस</span></h2>
        <p class="lead">Sunderkand is the fifth book in the Hindu epic the Ramayana. It depicts the adventures of Lord Hanuman. The original SundarKand is in Sanskrit and was composed by Valmiki, who was the first to scripturally record the Ramayana. रामायण पाठ में सुन्दरकाण्ड के पाठ का विशेष महत्व माना जाता है। सुंदरकाण्ड में हनुमान का लंका प्रस्थान, लंका दहन से लंका से वापसी तक के घटनाक्रम आते हैं।</p>
        <p><a class="btn btn-lg btn-danger" style="color:#fff;" routerLink="/sunderkand" role="button">Click here</a></p>
      </div>
      <div class="col-md-5 order-md-1">
          <img src="/assets/img/icon/hanuman.jpg" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" alt="">        
      </div>
    </div>

    <hr class="featurette-divider">

    <div class="row featurette">
      <div class="col-md-7">
        <h2 class="featurette-heading text-danger">Brahma | Vishnu | Mahesh <span class="text-muted"></span></h2>
        <p class="lead">Brahma: the creator of the universe, Vishnu: the preserver of the universe, and Mahesh (Shiva): the destroyer of the universe.</p>
      </div>
      <div class="col-md-5">
          <img src="/assets/img/icon/icon_bvm.jpg" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" alt="">        
      </div>
    </div>

    <hr class="featurette-divider">

    <div class="row featurette">
      <div class="col-md-7 order-md-2">
        <h2 class="featurette-heading text-danger" style="margin-top:50px;">नवदुर्गा आरतियाँ<span class="text-muted"></span></h2>
        <p class="lead">Navdurga Aartis: Aartis associated to each Goddess worshipped during Navratri.</p>
        <ol>
            <li><a routerLink="/aarti/navdurga-1-shailputri">Shailputri</a></li>
            <li><a routerLink="/aarti/navdurga-2-brahmacharini">Brahmacharini</a></li>
            <li><a routerLink="/aarti/navdurga-3-chandraghanta">Chandraghanta</a></li>
            <li><a routerLink="/aarti/navdurga-4-kushmanda">Kushmanda</a></li>
            <li><a routerLink="/aarti/navdurga-5-skandamata">Skandamata</a></li>
            <li><a routerLink="/aarti/navdurga-6-katyayani">Katyayani</a></li>
            <li><a routerLink="/aarti/navdurga-7-kalaratri">Kalaratri</a></li>
            <li><a routerLink="/aarti/navdurga-8-mahagauri">Mahagauri</a></li>
            <li><a routerLink="/aarti/navdurga-9-siddhidatri">Siddhidatri</a></li>
          </ol> 
      </div>
      <div class="col-md-5 order-md-1">
          <img src="/assets/img/icon/navdurga.jpg" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" alt="">        
      </div>
    </div>

    <hr class="featurette-divider">

    <!-- /END THE FEATURETTES -->

  </div><!-- /.container -->