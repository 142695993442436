<div class="container">
    <div class="jumbotron jumbotron-fluid">
        <div class="container">
          <h1 class="display-4 text-danger">Aarti Collection</h1>
          <p class="lead">Aarti is derived from the Sanskrit word आरात्रिक (ArAtrika) which means something that removes rātrī, darkness. आरती के महत्व की चर्चा सर्वप्रथम "स्कन्द पुराण" में की गयी है. आरती हिन्दू धर्म की पूजा परंपरा का एक अत्यंत महत्वपूर्ण कार्य है. किसी भी पूजा पाठ, यज्ञ, अनुष्ठान के अंत में देवी-देवताओं की आरती की जाती है. आरती की प्रक्रिया में, एक थाल में ज्योति और कुछ विशेष वस्तुएं रखकर भगवान के समक्ष घुमाते हैं.</p>
        </div>
    </div>
    
    <div class="row" style="text-align:center">      
    <app-aarti-item style="margin-top:25px;" 
      *ngFor="let aartiEl of aartis"
      [aarti]="aartiEl"
      [index]="aartiEl.url"></app-aarti-item>
    </div>
</div>