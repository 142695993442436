import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Chalisa } from '../chalisa.model';
import { ChalisaService } from '../chalisa.service';

@Component({
  selector: 'app-chalisa-list',
  templateUrl: './chalisa-list.component.html',
  styleUrls: ['./chalisa-list.component.css']
})
export class ChalisaListComponent implements OnInit {
  chalisas: Chalisa[];

  constructor(private chalisaService: ChalisaService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.chalisas = this.chalisaService.getChalisas();
  }

  // onNewChalisa() {
  //   this.router.navigate(['new'], {relativeTo: this.route});
  // }
}
