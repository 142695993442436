import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-durga-saptashati',
  templateUrl: './durga-saptashati.component.html',
  styleUrls: ['./durga-saptashati.component.css']
})
export class DurgaSaptashatiComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
