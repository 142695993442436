<div class="boq" [style.backgroundImage]="'url('+ chalisa.cover +')'">
  <div class="by">
    <div class="bor">
      <img class="us bos" [src]="chalisa.icon">
      <h3 class="bou">{{ chalisa.title }}</h3>
      <p class="bot">
        {{ chalisa.hindiName }}<BR> {{ chalisa.engName }}
      </p>
    </div>
  </div>
</div>

<div style="text-align: center;padding-top:180px;background: url('/assets/img/bg_subtle-pattern-2.jpg');">
<iframe width="320" height="180" [src]="videoUrl" frameborder="0" allowfullscreen></iframe>
</div>

<div innerHtml="{{chalisa.lyrics}}" class="noselect"
style="text-align: center;padding-top:10px;padding-bottom:30px;font-size: 18px;line-height: 2;
color:red;text-shadow: 0 1px 1px #000;background: url('/assets/img/bg_subtle-pattern-2.jpg');">
</div>

<hr class="featurette-divider">