import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AartisComponent } from './aartis/aartis.component';
import { AartiDetailComponent } from './aartis/aarti-detail/aarti-detail.component';
import { ChalisasComponent } from './chalisas/chalisas.component';
import { ChalisaDetailComponent } from './chalisas/chalisa-detail/chalisa-detail.component';
import { SunderkandComponent } from './sunderkand/sunderkand.component';
import { DurgaSaptashatiComponent } from './durga-saptashati/durga-saptashati.component';
import { NamaRamayanamComponent } from './nama-ramayanam/nama-ramayanam.component';
import { AboutComponent } from './about/about.component';

const appRoutes: Routes = [
  // { path: '', redirectTo: '/recipes', pathMatch: 'full' },
  { path: '', component: HomeComponent },
  { path: 'aarti', component: AartisComponent, children: [    
    { path: ':id', component: AartiDetailComponent },    
  ] },
  { path: 'chalisa', component: ChalisasComponent, children: [    
    { path: ':id', component: ChalisaDetailComponent },    
  ] },
  { path: 'sunderkand', component: SunderkandComponent},
  { path: 'durga-saptashati', component: DurgaSaptashatiComponent},
  { path: 'nama-ramayanam', component: NamaRamayanamComponent},
  { path: 'about', component: AboutComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes,{
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
