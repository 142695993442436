import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';

import { AartisComponent } from './aartis/aartis.component';
import { AartiListComponent } from './aartis/aarti-list/aarti-list.component';
import { AartiDetailComponent } from './aartis/aarti-detail/aarti-detail.component';
import { AartiItemComponent } from './aartis/aarti-list/aarti-item/aarti-item.component';

import { ChalisasComponent } from './chalisas/chalisas.component';
import { ChalisaListComponent } from './chalisas/chalisa-list/chalisa-list.component';
import { ChalisaDetailComponent } from './chalisas/chalisa-detail/chalisa-detail.component';
import { ChalisaItemComponent } from './chalisas/chalisa-list/chalisa-item/chalisa-item.component';
import { SunderkandComponent } from './sunderkand/sunderkand.component';
import { DurgaSaptashatiComponent } from './durga-saptashati/durga-saptashati.component';
import { NamaRamayanamComponent } from './nama-ramayanam/nama-ramayanam.component';
import { AboutComponent } from './about/about.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AartisComponent,
    AartiListComponent,
    AartiDetailComponent,
    AartiItemComponent,

    ChalisasComponent,
    ChalisaListComponent,
    ChalisaDetailComponent,
    ChalisaItemComponent,
    
    HomeComponent,
    FooterComponent,
    SunderkandComponent,
    DurgaSaptashatiComponent,
    NamaRamayanamComponent,
    AboutComponent    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule    
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
