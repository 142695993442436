<!-- <header data-mode="sticky" class="initial scroll-up">
  <nav class="primary-navigation fixed-top" data-theme="white" aria-label="Primary">

    <a routerLink="/" class="logo" title="Telepathy" itemscope="" itemtype="http://schema.org/Organization" itemprop="url">
      Bhakti | भक्ति </a>

    <a class="toggle" (click)="tog()"> <svg xmlns="http://www.w3.org/2000/svg" id="hamburger-nav" width="32" height="18" viewBox="0 0 32 18"><g fill="none" fill-rule="evenodd" stroke="#1E2122" stroke-linecap="square" stroke-width="2" transform="translate(1)"><path d="M0 1L30 1M0 9L30 9M0 17L30 17"></path></g></svg></a>

    <div class="menu initial" id="menu">
      <a class="toggle" (click)="tog()"> <svg xmlns="http://www.w3.org/2000/svg" width="14" height="26" viewBox="0 0 14 26"><g fill="none" fill-rule="evenodd" stroke="#FFF" stroke-linecap="square" stroke-width="2" transform="translate(2 2)"><path d="M0,11 L10,0"></path><path d="M0,22 L10,11" transform="matrix(1 0 0 -1 0 33)"></path></g></svg></a>

      <ul class="pages">
        <li routerLinkActive="active"><a routerLink="/">Home<br>भक्ति</a></li>
        <li routerLinkActive="active"><a routerLink="/aarti">Aarti Collection<br>भक्ति भक्ति</a></li>
        <li routerLinkActive="active"><a routerLink="/chalisa">Chalisa Collection<br>भक्ति भक्ति</a></li>
        <li routerLinkActive="active"><a routerLink="/durga-saptashati">Durga Saptashati<br>भक्ति भक्ति</a></li>
        <li routerLinkActive="active"><a routerLink="/sunderkand">Sunderkand<br>भक्ति भक्ति</a></li>
        <li routerLinkActive="active"><a routerLink="/nama-ramayanam">Nama Ramayanam<br>भक्ति भक्ति</a></li>
        <li routerLinkActive="active"><a routerLink="/about">About<br>भक्ति भक्ति</a></li>
      </ul>
      <nav class="social" aria-label="Social">
        <ul class="social-links">
          <li>
            <a href="https://www.instagram.com/#/" target="_blank" title="Instagram"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792"><path d="M1490 1426v-648h-135q20 63 20 131 0 126-64 232.5t-174 168.5-240 62q-197 0-337-135.5t-140-327.5q0-68 20-131h-141v648q0 26 17.5 43.5t43.5 17.5h1069q25 0 43-17.5t18-43.5zm-284-533q0-124-90.5-211.5t-218.5-87.5q-127 0-217.5 87.5t-90.5 211.5 90.5 211.5 217.5 87.5q128 0 218.5-87.5t90.5-211.5zm284-360v-165q0-28-20-48.5t-49-20.5h-174q-29 0-49 20.5t-20 48.5v165q0 29 20 49t49 20h174q29 0 49-20t20-49zm174-208v1142q0 81-58 139t-139 58h-1142q-81 0-139-58t-58-139v-1142q0-81 58-139t139-58h1142q81 0 139 58t58 139z"></path></svg> </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/#" target="_blank" title="LinkedIn"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 857 819"><path d="M11,266V819H195V266ZM178,27Q150,0,104,0T29,27Q0,54,0,95t28,68q28,27,74,27h1q46,0,75-27t29-68Q206,54,178,27ZM799,316q-58-63-153-63a279.67,279.67,0,0,0-64,9q-30,8-49,24a345.56,345.56,0,0,0-31,29,197.06,197.06,0,0,0-23,31h1V266H296l1,27V458q0,138-1,361H480V510q0-28,6-45,13-29,36-48t58-19q48,0,71,33t23,92V819H857V502Q857,379,799,316Z"></path></svg> </a>
          </li>
          <li>
            <a href="https://www.facebook.com/#" target="_blank" title="Facebook"> <svg
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792"><path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z"></path></svg> </a>
          </li>
          <li>
            <a href="https://twitter.com/#" target="_blank" title="Twitter"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792"><path d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z"></path></svg></a>
          </li>
          <li>
            <a href="https://dribbble.com/#" target="_blank" title="Dribbble"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792"><path d="M1152 1500q-42-241-140-498h-2l-2 1q-16 6-43 16.5t-101 49-137 82-131 114.5-103 148l-15-11q184 150 418 150 132 0 256-52zm-185-607q-21-49-53-111-311 93-673 93-1 7-1 21 0 124 44 236.5t124 201.5q50-89 123.5-166.5t142.5-124.5 130.5-81 99.5-48l37-13q4-1 13-3.5t13-4.5zm-107-212q-120-213-244-378-138 65-234 186t-128 272q302 0 606-80zm684 319q-210-60-409-29 87 239 128 469 111-75 185-189.5t96-250.5zm-805-741q-1 0-2 1 1-1 2-1zm590 145q-185-164-433-164-76 0-155 19 131 170 246 382 69-26 130-60.5t96.5-61.5 65.5-57 37.5-40.5zm223 485q-3-232-149-410l-1 1q-9 12-19 24.5t-43.5 44.5-71 60.5-100 65-131.5 64.5q25 53 44 95 2 6 6.5 17.5t7.5 16.5q36-5 74.5-7t73.5-2 69 1.5 64 4 56.5 5.5 48 6.5 36.5 6 25 4.5zm112 7q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path></svg></a>
          </li>
        </ul> <small role="contentinfo">© 2000-2019 NitinK (formerly Digital Telepathy).<br> All rights reserved.
          <a href="https://www.dtelepathy.com/privacy-policy/">Privacy Policy</a></small>
      </nav>
    </div>
  </nav>
</header> -->














<header>
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top bg-danger" style="background-color: #7f0f40 !important;">
      <a class="navbar-brand" routerLink="/" style="display: flex;align-items: center;">
        <img src="/assets/img/logo.png" width="60" height="60" class="d-inline-block align-top" alt="">
        <span style="padding-left: 15px;font-size: 25px;">Bhakti | भक्ति</span>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse" style="background: rgb(127, 15, 64);padding: 10px 20px;margin: -8px -15px 0px;font-size: 17px;">
        <ul class="navbar-nav mr-auto">          
          <li class="nav-item" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse">
            <a class="nav-link" routerLink="/aarti">Aarti Collection</a>
          </li>
          <li class="nav-item" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse">
            <a class="nav-link " routerLink="/chalisa">Chalisa Collection</a>
          </li>          
          <li class="nav-item" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse">
            <a class="nav-link " routerLink="/durga-saptashati">Durga Saptashati</a>
          </li>
          <li class="nav-item" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse">
            <a class="nav-link " routerLink="/sunderkand">Sunderkand</a>
          </li>
          <li class="nav-item" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse">
            <a class="nav-link " routerLink="/nama-ramayanam">Nama Ramayanam</a>
          </li>          
        </ul>        
      </div>
    </nav>
  </header>


  <!-- <li class="nav-item" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse">
            <a class="nav-link " routerLink="/Stotram">Stotram Collection</a>
          </li>
          <li class="nav-item" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse">
            <a class="nav-link " routerLink="/">Ashtakam Collection</a>
          </li>
          <li class="nav-item" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse">
            <a class="nav-link " routerLink="/mantra">Vedic Mantra</a>
          </li>
          <li class="nav-item" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse">
            <a class="nav-link " routerLink="/">Vedic Yantra</a>
          </li>
          <li class="nav-item" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse">
            <a class="nav-link " routerLink="/">Deity Names</a>
          </li> -->