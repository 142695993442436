import { Component, OnInit } from '@angular/core';

import { Chalisa } from './chalisa.model';
import { ChalisaService } from './chalisa.service';

@Component({
  selector: 'app-chalisas',
  templateUrl: './chalisas.component.html',
  styleUrls: ['./chalisas.component.css'],
  providers: [ChalisaService]
})
export class ChalisasComponent implements OnInit {
  selectedChalisa: Chalisa;

  constructor(private chalisaService: ChalisaService) { }

  ngOnInit() {
    this.chalisaService.chalisaSelected
      .subscribe(
        (chalisa: Chalisa) => {
          this.selectedChalisa = chalisa;
        }
      );
  }

}
