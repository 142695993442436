<div class="container">  
    <div class="jumbotron jumbotron-fluid">
      <div class="container">
        <h1 class="display-4 text-danger">About US</h1>
        <p class="lead">‘Bhakti’ word means the path of loving devotion to a particular God or Deity, it is believed, leads to salvation or nirvana. Everyone who believes in Hinduism follows many spiritual Gods or Deity. This platform provides direct connectivity to those Gods or Deity via Mantras, Stotram, Aarti, Chalisa. These spiritual lyrics are in Sanskrit, Hindi, Awadhi, and English language versions.
        <BR><BR>Bhakti.Dev gives easy and user-friendly interface for access to thousand lines of Sanskrit Mantras. It also provides important prayer for the particular day.
        <BR><BR>भक्ति तर्क पर नहीं, श्रद्धा एवं विश्वास पर अवलंबित है। पुरुष ज्ञान से भी अधिक श्रद्धामय है। मनुष्य जैसा विचार करता है, वैसा बन जाता है, इससे भी अधिक सत्य इस कथन में है कि मनुष्य की जैसी श्रद्धा होती है उसी के अनुकूल और अनुपात में उसका निर्माण होता है।
          <div class="text-center font-weight-bold text-danger lead">
              🌻🌻<BR>
              भक्ति जब भोजन में प्रवेश करती है,<BR>
              भोजन "प्रसाद" बन जाता है.<BR>
              🌻🌻<BR>
              भक्ति जब भूख में प्रवेश करती है,<BR>
              भूख "व्रत" बन जाती है.।<BR>
              🌻🌻<BR>
              भक्ति जब पानी में प्रवेश करती है,<BR>
              पानी "चरणामृत" बन जाता है.।<BR>
              🌻🌻<BR>
              भक्ति जब सफर में प्रवेश करती है,<BR>
              सफर "तीर्थयात्रा" बन जाता है.।<BR>
              🌻🌻<BR>
              भक्ति जब संगीत में प्रवेश करती है,<BR>
              संगीत "कीर्तन" बन जाता है.।<BR>
              🌻🌻<BR>
              भक्ति जब घर में प्रवेश करती है,<BR>
              घर "मन्दिर" बन जाता है.।<BR>
              🌻🌻<BR>
              भक्ति जब कार्य में प्रवेश करती है,<BR>
              कार्य "कर्म" बन जाता है.।<BR>
              🌻🌻<BR>
              भक्ति जब क्रिया में प्रवेश करती है,<BR>
              क्रिया "सेवा" बन जाती है.। और…<BR>
              🌻🌻<BR>
              भक्ति जब व्यक्ति में प्रवेश करती है,<BR>
              व्यक्ति "मानव" बन जाता है..।  
          </div>
      </div>
    </div>
    </div>
    
    <hr class="featurette-divider">