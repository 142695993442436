import { Component, OnInit } from '@angular/core';

import { Aarti } from './aarti.model';
import { AartiService } from './aarti.service';

@Component({
  selector: 'app-aartis',
  templateUrl: './aartis.component.html',
  styleUrls: ['./aartis.component.css'],
  providers: [AartiService]
})
export class AartisComponent implements OnInit {
  selectedAarti: Aarti;

  constructor(private aartiService: AartiService) { }

  ngOnInit() {
    this.aartiService.aartiSelected
      .subscribe(
        (aarti: Aarti) => {
          this.selectedAarti = aarti;
        }
      );
  }

}
