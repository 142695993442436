export class Aarti {
  public title: string;
  public url: string;
  public god: string;
  public hindiName: string;
  public engName: string;
  public lyrics: string;
  public icon: string;
  public cover: string;
  public video: string;

  constructor(title: string, url: string, god: string, hindiName: string, engName: string, lyrics: string, icon: string, cover: string, video: string,) {
    this.title = title;
    this.url = url;
    this.god = god;
    this.hindiName = hindiName;
    this.engName = engName;
    this.lyrics = lyrics;
    this.icon = icon;
    this.cover = cover;
    this.video = video;
  }
}
