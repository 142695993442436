import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Aarti } from '../aarti.model';
import { AartiService } from '../aarti.service';

@Component({
  selector: 'app-aarti-list',
  templateUrl: './aarti-list.component.html',
  styleUrls: ['./aarti-list.component.css']
})
export class AartiListComponent implements OnInit {
  aartis: Aarti[];

  constructor(private aartiService: AartiService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.aartis = this.aartiService.getAartis();
  }

  // onNewAarti() {
  //   this.router.navigate(['new'], {relativeTo: this.route});
  // }
}
