import { Component, OnInit, Input } from '@angular/core';

import { Aarti } from '../../aarti.model';

@Component({
  selector: 'app-aarti-item',
  templateUrl: './aarti-item.component.html',
  styleUrls: ['./aarti-item.component.css']
})
export class AartiItemComponent implements OnInit {
  @Input() aarti: Aarti;
  @Input() index: string;

  ngOnInit() {
  }
}
