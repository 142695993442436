import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Chalisa } from '../chalisa.model';
import { ChalisaService } from '../chalisa.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-chalisa-detail',
  templateUrl: './chalisa-detail.component.html',
  styleUrls: ['./chalisa-detail.component.css']
})
export class ChalisaDetailComponent implements OnInit {
  chalisa: Chalisa;
  id: string;

  dangerousVideoUrl: string;
  videoUrl: SafeResourceUrl;

  constructor(private chalisaService: ChalisaService,
              private route: ActivatedRoute,
              private router: Router,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.route.params
      .subscribe(
        (params: Params) => {
          this.id = params['id'];
          this.chalisa = this.chalisaService.getChalisa(this.id);

          //video urn change every time with url change
          this.dangerousVideoUrl = this.chalisa.video;
          this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousVideoUrl);  
        }
      );
      
  }



  // onEditChalisa() {
  //   this.router.navigate(['edit'], {relativeTo: this.route});
  //   // this.router.navigate(['../', this.id, 'edit'], {relativeTo: this.route});
  // }

}
