import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nama-ramayanam',
  templateUrl: './nama-ramayanam.component.html',
  styleUrls: ['./nama-ramayanam.component.css']
})
export class NamaRamayanamComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
