<!-- FOOTER -->
<footer class="container" style="clear:both; margin-top:50px;">
  <!-- <p class="float-right"><a routerLink="/">Back to top</a></p> -->
  <p style="line-height: 30px;">&copy; Bhakti.Dev &middot; 2019-2023 <BR>
    <a routerLink="/about">About Us</a> &middot; Contact Us at: ramramji@bhakti.dev<BR>
    <a routerLink="/">Home</a> &middot;
    <a routerLink="/aarti">Aarti Collection</a> &middot;
    <a routerLink="/chalisa">Chilisa Collection</a> &middot;
    <a routerLink="/durga-saptashati">Durga Saptashati</a> &middot;
    <a routerLink="/sunderkand">Sunderkand</a> &middot;
    <a routerLink="/nama-ramayanam">Nama Ramayanam</a> <BR>
    <span class="font-weight-light">Design and Developed by: <a href="https://nitink.com/">NitinK.com</a></span>

    <!-- &middot; <a routerLink="/">Privacy</a> &middot; <a routerLink="/">Terms</a> -->
  </p>
</footer>